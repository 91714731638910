<template>
  <div class="row">
    <div v-if="isQuickBookSyncing" style="position: absolute;
    width: 100%;
    z-index: 1;
    background: rgb(71 71 71 / 20%);
    height: 100%;
    display: flex
;">
      <vDataLoader></vDataLoader>
    </div>
    <div class="col-md-12" style="background: white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="3">
              <input
                v-model="searchInput"
                type="text"
                placeholder="Search Company Name"
                class="form-control"
              />
            </th>
            <th></th>
            <th></th>
            <th colspan="5">
              <router-link
                :to="{ name: 'client.master.company.create' }"
                v-if="currentUser.u.roles == 'admin'"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Company
                </div>
              </router-link>
            </th>
          </tr>
          <tr>
            <th>SN</th>
            <th scope="col">Company Name</th>
            <th>Contact Name</th>

            <th class="text-center">Use in Archive</th>
            <th scope="col" class="text-center">Status</th>
            <th scope="col" class="text-center">QuickBooks Synced</th>
            <th
              style="width: 14%;"
              scope="col"
              v-if="currentUser.u.roles == 'admin'"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredMasterCompanies.length > 0">
          <tr v-for="(item, index) in filteredMasterCompanies" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.company_name }}</td>

            <td>{{ item.contact_name }}</td>

            <td class="text-center">
              <i
                class="fa fa-times text-danger"
                v-if="!item.use_in_archive"
              ></i>
              <i
                class="fa fa-check text-success"
                v-if="item.use_in_archive"
              ></i>
            </td>
            <td class="text-center">
              <i class="fa fa-times text-danger" v-if="!item.is_active"></i>
              <i class="fa fa-check text-success" v-if="item.is_active"></i>
            </td>
            <td class="text-center">
              <i class="fa fa-times text-danger" v-if="!item.is_quickbooks_synced"></i>
              <i class="fa fa-check text-success" v-if="item.is_quickbooks_synced"></i>
            </td>
            <td v-if="currentUser.u.roles == 'admin'">
              <button
                @click="editData(item.id)"
                class="btn btn-info btn-sm mr-3 mt-2"
              >
                Edit
              </button>
              <button
                @click="syncQuickBooks(item.id)"
                class="btn btn-success btn-sm mr-3 mt-2"
              >
              {{ item.is_quickbooks_synced ? 'Update' : 'Sync' }} QuickBooks
              </button>
              <button
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm mr-3 mt-2"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredMasterCompanies.length < 1">
          <tr>
            <td colspan="10" class="text-center">
              No record(s) found
            </td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
        <!----><!---->
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_MASTER_COMPANIES,
  DELETE_MASTER_COMPANY,
  SYNC_QUICKBOOK_COMPANY
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Companies",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: "",
      isQuickBookSyncing: false,
    };
  },
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["getMasterCompanies", "currentUser"]),
    filteredMasterCompanies() {
      let tempCompany = this.getMasterCompanies;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempCompany = tempCompany.filter(item => {
          return item.company_name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempCompany;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getMasterCompaniesData();
  },
  filters: {
    convertDateFormat(value) {
      return moment(value).format("MM/DD/YYYY");
    }
  },
  methods: {
    deleteItem(id, index) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover !",

        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_MASTER_COMPANY, id).then(data => {
            this.$toastr.s(data.msg);
            this.getMasterCompanies.splice(index, 1);
          });
        }
      });
    },
    syncQuickBooks(id){
      this.isQuickBookSyncing = true;
      this.$store.dispatch(SYNC_QUICKBOOK_COMPANY, id)

      .then(r => {
        this.isQuickBookSyncing = false;
        this.$toastr.s("Synced Successfully");
        this.getMasterCompaniesData();
      })
      .catch(e => {
        this.isQuickBookSyncing = false;
        this.$toastr.e(e.msg);
      });;
    },
    editData(id) {
      this.$router.push({
        name: "client.master.company.edit",
        params: { id: id }
      });
    },
    getMasterCompaniesData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_MASTER_COMPANIES)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  }
};
</script>

<style></style>
